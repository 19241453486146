import React from "react"
import Topbar from "../../../components/organisms/topbar"
import CustomLayout from "../../../Layouts/Layout"
import NewProductPage from "./NewProductPage"
import "./style.scss"

const IndexPage = () => {
  return (
    <div className="comodity-new-product">
      <Topbar />
      <CustomLayout selectedPage={<NewProductPage />} />
    </div>
  )
}

export default IndexPage
